<template>
  <div class="py-4 px-10">
    <loading :active.sync="loading"></loading>
    <h2 class="headtitle">สรุปจำนวนการ x-ray</h2>
    <v-row v-if="opendiv199">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          @change="onChangeArea()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="opendiv2">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="opendiv3">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="opendiv4">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
          disabled
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <v-menu
          ref="menufrom"
          v-model="menufrom"
          :close-on-content-click="false"
          :return-value.sync="datefrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datefrom"
              label="วันที่เริ่ม"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datefrom"
            no-title
            scrollable
            :min="firstDate"
          >
            <v-btn text color="primary" @click="menufrom = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menufrom.save(datefrom)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          ref="menuto"
          v-model="menuto"
          :close-on-content-click="false"
          :return-value.sync="dateto"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateto"
              label="วันที่สิ้นสุด"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateto" no-title scrollable>
            <v-btn text color="primary" @click="menuto = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menuto.save(dateto)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="12" sm="12" xs="12">
        <v-row align="center" justify="center">
          <v-btn width="80px" class="mt-2 mb-4 mr-4" @click="clear()"
            >เคลียร์</v-btn
          >
          <v-btn
            width="80px"
            class="mt-2 mb-4"
            @click="getReportXray()"
            color="#833133"
            dark
            >ค้นหา</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-col cols="12" md="12" sm="12" xs="12">
      <v-row align="center" justify="center">
        <v-btn
          width="80px"
          class="mt-2 mb-4"
          @click="getReportXray()"
          color="#833133"
          dark
          >ค้นหา</v-btn
        >
      </v-row>
    </v-col> -->
    <div v-if="showTable">
      <v-row align="center" justify="center">
        <v-col cols="12" md="4" sm="12" class="mr-4">
          <v-text-field
            class="mb-4"
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา สภ./จังหวัด/ภาค"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="4" sm="12" class="mr-8 mb-4"
        ><ExportXray :list1="list1"
        /></v-col>
        <v-col cols="12" md="4" sm="12" class="mr-8 mb-4"
          ><ExportAllStation :list1="list2"
        /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            :items="list1"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.notInput`]="{ item }">
              <span v-if="item.notInput == null">0</span>
              <span v-else>{{ item.notInput }}</span>
            </template>
            <template v-slot:[`item.found`]="{ item }">
              <span v-if="item.found == null">0</span>
              <span v-else>{{ item.found }}</span>
            </template>
            <template v-slot:[`item.notFound`]="{ item }">
              <span v-if="item.notFound == null">0</span>
              <span v-else>{{ item.notFound }}</span>
            </template>
            <template v-slot:[`item.percentXray`]="{ item }">
              <span>{{
                item.xray1 > 0
                  ? ((item.sumAmt * 100) / item.xray1).toFixed(2) + "%"
                  : "-"
              }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import ExportXray from "@/views/ExportExcel/ExportXray";
import ExportAllStation from "@/views/ExportExcel/ExportAllStation";
export default {
  components: { ExportXray, Loading, ExportAllStation },
  data() {
    return {
      loading: false,
      showTable: false,
      search: "",
      list1: [],
      list2: [],
      count: 0,
      headers: [
        { text: "ลำดับ", value: "count", align: "center", width: "50px" },
        { text: "ชื่อสภ", value: "station", align: "center", width: "100px" },
        { text: "จังหวัด", value: "province", align: "center", width: "100px" },
        { text: "ภาค", value: "area", align: "center", width: "100px" },
        {
          text: "ประชากร ทร.14",
          value: "sumPeople14",
          align: "center",
          width: "100px",
        },
        {
          text: "จำนวนครัวเรือน",
          value: "sumHouse",
          align: "center",
          width: "100px",
        },
        {
          text: "จำนวนประชากรจริง",
          value: "people1",
          align: "center",
          width: "100px",
        },
        {
          text: "จำนวนครัวเรือนจริง",
          value: "people2",
          align: "center",
          width: "100px",
        },
        {
          text: "MOU ครัวเรือน",
          value: "mou1",
          align: "center",
          width: "100px",
        },
        {
          text: "จำนวนคุ้ม (คุ้ม)",
          value: "people3",
          align: "center",
          width: "100px",
        },
        {
          text: "เป้าหมายการ x-ray ในชุมชน",
          value: "xray1",
          align: "center",
          width: "100px",
        },
        {
          text: "จำนวนการ x-ray",
          value: "sumAmt",
          align: "center",
          width: "100px",
        },
        {
          text: "ร้อยละการ x-ray",
          value: "percentXray",
          align: "center",
          width: "100px",
        },
        {
          text: "พบสารเสพติด",
          value: "found",
          align: "center",
          width: "100px",
        },
        {
          text: "ไม่พบสารเสพติด",
          value: "notFound",
          align: "center",
          width: "100px",
        },
        {
          text: "เข้ารับการบำบัด",
          value: "goin",
          align: "center",
          width: "100px",
        },
        {
          text: "ไม่เข้ารับการบำบัด",
          value: "notgoin",
          align: "center",
          width: "100px",
        },
        {
          text: "จำนวนสมาชิกคุ้ม",
          value: "gangsAmt",
          align: "center",
          width: "100px",
        },
        // {
        //   text: "พบสารเสพติดครั้งที่ 2",
        //   value: "urine2Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 2",
        //   value: "urine2NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 3",
        //   value: "urine3Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 3",
        //   value: "urine3NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 4",
        //   value: "urine4Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 4",
        //   value: "urine4NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 5",
        //   value: "urine5Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 5",
        //   value: "urine5NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 6",
        //   value: "urine6Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 6",
        //   value: "urine6NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 7",
        //   value: "urine7Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 7",
        //   value: "urine7NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 8",
        //   value: "urine8Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 8",
        //   value: "urine8NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 9",
        //   value: "urine9Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 9",
        //   value: "urine9NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 10",
        //   value: "urine10Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 10",
        //   value: "urine10NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 11",
        //   value: "urine11Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 11",
        //   value: "urine11NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 12",
        //   value: "urine12Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 12",
        //   value: "urine12NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 13",
        //   value: "urine13Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 13",
        //   value: "urine13NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 14",
        //   value: "urine14Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 14",
        //   value: "urine14NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 15",
        //   value: "urine15Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 15",
        //   value: "urine15NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "พบสารเสพติดครั้งที่ 16",
        //   value: "urine16Found",
        //   align: "center",
        //   width: "100px",
        // },
        // {
        //   text: "ไม่พบสารเสพติดครั้งที่ 16",
        //   value: "urine16NotFound",
        //   align: "center",
        //   width: "100px",
        // },
        { text: "ไม่ได้กรอก", value: "notInput", align: "center" },

        // notInput: 635
      ],
      opendiv199: false,
      opendiv2: false,
      opendiv3: false,
      opendiv4: false,
      items: [],
      items2: [
        { text: "ทั่วประเทศ", group: "" },
        { text: "นครบาล", group: "น." },
        { text: "ภาค1", group: "ภ.1" },
        { text: "ภาค2", group: "ภ.2" },
        { text: "ภาค3", group: "ภ.3" },
        { text: "ภาค4", group: "ภ.4" },
        { text: "ภาค5", group: "ภ.5" },
        { text: "ภาค6", group: "ภ.6" },
        { text: "ภาค7", group: "ภ.7" },
        { text: "ภาค8", group: "ภ.8" },
        { text: "ภาค9", group: "ภ.9" },
      ],
      items3: [],
      items4: [],
      data: {
        area: "",
        province: "",
        station: "",
      },
      checklogin: "",
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      firstDate: new Date("2021-05-01").toISOString().slice(0, 10),
    };
  },

  created() {
    this.getUser();
    this.datefrom = new Date("2021-05-01").toISOString().substr(0, 10);
    // this.getReportXray();
    // this.searchDropdown();
  },
  methods: {
    clear() {
      this.data.area = "";
      this.data.province = "";
      this.data.station = "";
      this.datefrom = new Date("2021-05-01").toISOString().substr(0, 10);
      this.dateto = new Date().toISOString().substr(0, 10);
      // this.getUser();
      // this.getReportXray();
    },
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.data.province = "";
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` + this.data.area
      );
      console.log("onChangeArea", response.data.data);
      this.items3 = response.data.data;

      // this.items4 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.data.province
      );
      this.items4 = response.data.data;

      // this.items4 = response.data.data;
    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);

      this.data.area = Array.from(
        new Set(this.items.map((a) => a.group.trim()))
      ).map((group) => {
        return this.items.find((a) => a.group.trim() === group.trim());
      });
      console.log("this.item2", this.data.area);
    },
    async getUser() {
      this.list1 = [];
      this.list2 = [];
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      console.log(this.checklogin);
      // var data;

      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        this.opendiv199 = true;
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/sumAllStation`
        );
        this.list2 = response.data.data[0];
        console.log("list2", response);
      } else if (this.checklogin.userType == "2") {
        this.data = {
          area: this.checklogin.area || "",
          province: "",
          station: "",
        };
        this.onChangeArea();
        this.opendiv2 = true;
      } else if (this.checklogin.userType == "3") {
        this.data = {
          area: this.checklogin.area || "",
          province: this.checklogin.province || "",
          station: "",
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.onChangeProvince();

        // console.log(this.data);
        this.opendiv3 = true;
      } else if (this.checklogin.userType == "4") {
        const responseUser = await this.axios.get(
          `${process.env.VUE_APP_API}/user/checkStation?station=${this.checklogin.station}`
        );
        console.log("response", responseUser);
        if (responseUser.data.response_status === "ERROR") {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
            icon: "error",
            text: "ข้อมูลสถานีของท่านไม่ถูกต้อง กรุณา Login ใหม่อีกครั้ง",
          });
          localStorage.removeItem("user");
          window.location.reload();
        }
        this.data = {
          area: this.checklogin.area,
          province: this.checklogin.province,
          station: this.checklogin.station,
        };
        const response1 = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.checklogin.area
        );
        this.items3 = response1.data.data;

        const response2 = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?province=` +
            this.checklogin.province
        );
        this.items4 = response2.data.data;
        this.opendiv4 = true;

        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/drugrelatedlist/findByUserId`,
          { userId: this.checklogin.id }
        );
        console.log("response1", response);
        console.log("response", response.data.data);
        this.listdrug = response.data.data;
        for (let i in this.listdrug) {
          this.listdrug[i].count = parseInt(i) + 1;
          //console.log(this.list);
        }
        this.opendiv4 = true;
      }
      // this.getReportXray();
    },
    async getReportXray() {
      this.showTable = true;
      this.loading = true;
      console.log("xrayReport?group=", this.data);
      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
      this.list2 = [];
      const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/sumAllStation?fromDate=` +
          this.datefrom +
          "&toDate=" +
          this.dateto
        );
        this.list2 = response.data.data[0];
      }
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/xrayReport?group=` +
          // `http://localhost:8081/form/xrayReport?group=` +
          this.data.area +
          "&province=" +
          this.data.province +
          "&station=" +
          this.data.station +
          "&fromDate=" +
          this.datefrom +
          "&toDate=" +
          this.dateto
      );
      console.log("data", response.data.data[0]);
      this.list1 = response.data.data[0];
      for (let i in this.list1) {
        this.list1[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>